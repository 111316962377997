// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { defineMessages, useIntl } from 'react-intl';

// components
import SkewContainer from '@components/SkewContainer';

// styles
import { Container, TextWrapper } from './MissionSection.styles';

const query = graphql`
  query {
    file(name: { eq: "img-skew-home-page" }) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const messages = defineMessages({
  missionSectionId: {
    defaultMessage: 'notre-mission',
    description: 'Id of the mission section in the home page',
  },
});

const MissionSection = ({ children }) => {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const data = useStaticQuery(query);

  const {
    file: {
      childImageSharp: { fluid },
    },
  } = data;

  return (
    <Container id={formatMessage(messages.missionSectionId)}>
      <SkewContainer picture={fluid} bgColorAfter={theme.colors.veryLightPink}>
        <TextWrapper>{children}</TextWrapper>
      </SkewContainer>
    </Container>
  );
};

MissionSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MissionSection;
