// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import { Container, Wrapper, Stack } from './ReasonsSection.styles';

const messages = defineMessages({
  reasonsSectionId: {
    defaultMessage: 'choisir-permafil',
    description: 'Id of the reasons section in the home page',
  },
});

const ReasonsSection = ({ children }) => {
  const { formatMessage } = useIntl();

  return (
    <Container id={formatMessage(messages.reasonsSectionId)}>
      <Wrapper>
        <Stack>{children}</Stack>
      </Wrapper>
    </Container>
  );
};

ReasonsSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReasonsSection;
