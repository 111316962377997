/* eslint-disable import/prefer-default-export */
// vendors
import styled, { css } from 'styled-components';
import { magnifyStyle } from '@styles/global';
import { lessThan } from '@utils/mediaQuery';

const sectionTitleStyle = css`
  ${magnifyStyle};

  color: ${({ theme }) => theme.colors.secondary};

  ${lessThan(1024)} {
    font-size: ${22 / 16}rem;
  }
`;

export const Container = styled.section``;

export const TextWrapper = styled.div`
  h2 {
    ${sectionTitleStyle}

    margin-bottom: ${60 / 22}rem;
  }
`;
