import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { defineMessages, useIntl } from 'react-intl';
import { hideVisually } from 'polished';

import CardGrid, { Card } from '@components/CardGrid';

import { Container, Wrapper } from './ProfilingSection.styles';

const query = graphql`
  query {
    businessesProfilePicture: file(
      name: { eq: "img-card-businesses-1304x1400" }
    ) {
      ...CardLongPicture
    }
    consumersProfilePicture: file(
      name: { eq: "img-card-consumers-1304x1400" }
    ) {
      ...CardLongPicture
    }
  }
`;

const messages = defineMessages({
  profilingSectionId: {
    defaultMessage: 'profils',
    description: 'Id of the profiling section in the home page',
  },
  profilingSectionTitle: {
    defaultMessage: 'Nos produits',
    description: 'Title of the profiling section in the home page',
  },
  businessesProfileCardTitle: {
    defaultMessage: 'Entreprises',
    description: 'Title of the business profile card in the home page',
  },
  businessesProfileCardDescription: {
    defaultMessage: 'Produits et services pour les professionnels',
    description: 'Description of the business profile card in the home page',
  },
  businessesProfileCardPath: {
    defaultMessage: '/entreprises',
    description:
      'Path to business page from business profile card in the home page',
  },
  consumersProfileCardTitle: {
    defaultMessage: 'Particuliers',
    description: 'Title of the consumers card profile in the home page',
  },
  consumersProfileCardDescription: {
    defaultMessage: 'Produits de rangements pour votre quotidien',
    description: 'Description of the consumers profile card in the home page',
  },
  consumersProfileCardPath: {
    defaultMessage: '/particuliers',
    description:
      'Path to consumer page from consumers profile card in the home page',
  },
});

const ProfilingSection = () => {
  const { formatMessage } = useIntl();

  const data = useStaticQuery(query);

  const {
    businessesProfilePicture: {
      childImageSharp: { fluid: businessesProfilePicture },
    },
    consumersProfilePicture: {
      childImageSharp: { fluid: consumersProfilePicture },
    },
  } = data;

  return (
    <Container id={formatMessage(messages.profilingSectionId)}>
      <Wrapper>
        <h2 css={hideVisually}>
          {formatMessage(messages.profilingSectionTitle)}
        </h2>

        <CardGrid>
          <Card
            titleAs='h3'
            tall
            picture={businessesProfilePicture}
            to={formatMessage(messages.businessesProfileCardPath)}
            title={formatMessage(messages.businessesProfileCardTitle)}
            description={formatMessage(
              messages.businessesProfileCardDescription
            )}
          />

          <Card
            titleAs='h3'
            tall
            primary
            picture={consumersProfilePicture}
            to={formatMessage(messages.consumersProfileCardPath)}
            title={formatMessage(messages.consumersProfileCardTitle)}
            description={formatMessage(
              messages.consumersProfileCardDescription
            )}
          />
        </CardGrid>
      </Wrapper>
    </Container>
  );
};

export default ProfilingSection;
