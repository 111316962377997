// vendors
import styled from 'styled-components';
import { rem } from 'polished';

// utils
import { lessThan } from '@utils/mediaQuery';

export const Container = styled.section`
  margin: ${rem(128, 22)} 0;
  padding: 0 24px;

  ${lessThan(321)} {
    margin: ${rem(60, 16)} 0;
  }
`;

export const Wrapper = styled.div`
  max-width: 1408px;

  margin: auto;
`;
