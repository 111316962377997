// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

// components
import ItemCardGrid from '@components/ItemCardGrid';
import ItemCard from '@components/ItemCardGrid/ItemCard';
import generatePath from '@utils/generatePath';

// styles
import { titleStyle } from '@styles/global';
import { Container, Wrapper } from './ProjectsSection.styles';

const messages = defineMessages({
  projectsSectionId: {
    defaultMessage: 'certaines-de-nos-realisations',
    description: 'Id of the projects section in the home page',
  },
  projectsSectionButtonText: {
    defaultMessage: 'Détails',
    description: 'Text of the projects section button in the home page',
  },
});

const ProjectsSection = ({ projects }) => {
  const { formatMessage } = useIntl();

  const nodes = projects.map((project) => {
    const {
      _type: type,
      localization: { locale },
      slug: { current: slug },
    } = project;

    const path = generatePath(slug, locale, type);

    return {
      title: project.title,
      complement: `${project.city}, ${project.state}` || 'Québec, QC',
      picture: project.pictures[0].asset.fluid,
      buttonText: formatMessage(messages.projectsSectionButtonText),
      to: path,
    };
  });

  return (
    <Container id={formatMessage(messages.projectsSectionId)}>
      <Wrapper>
        <h2 css={titleStyle}>
          <FormattedMessage
            defaultMessage='Certaines de nos réalisations'
            description='Projects section title in the home page'
          />
        </h2>

        <ItemCardGrid>
          {nodes.map((node) => (
            <ItemCard titleAs='h3' key={node.to} {...node} />
          ))}
        </ItemCardGrid>
      </Wrapper>
    </Container>
  );
};

ProjectsSection.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      _type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      pictures: PropTypes.arrayOf(
        PropTypes.shape({
          asset: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number.isRequired,
              src: PropTypes.string.isRequired,
              srcSet: PropTypes.string.isRequired,
              srcSetWebp: PropTypes.string.isRequired,
              srcWebp: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        })
      ).isRequired,
      localization: PropTypes.shape({
        locale: PropTypes.string.isRequired,
      }).isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ),
};
ProjectsSection.defaultProps = {
  projects: [],
};

export default ProjectsSection;
