import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import Button from '../../Button';
import { outlineFocusHoverStyle } from '../../Button/Button.styles';
import { lessThan } from '../../../utils/mediaQuery';

export const Picture = styled(Img)`
  min-height: 186px;
`;

export const ItemCardContent = styled.div`
  ${({ theme }) =>
    theme.layouts.box(
      theme.colors.secondary,
      theme.colors.tertiary,
      0,
      theme.spaces.s0
    )};

  ${({ theme }) => theme.layouts.stack(`${theme.spaces.sm3}rem`)}

  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

export const ItemCardText = styled.div`
  flex-grow: 1;
`;

export const itemCardTypo = css`
  font-weight: ${({ theme }) => theme.itemCard.fontWeight};
  font-size: ${({ theme }) => theme.itemCard.fontSize}rem;
  line-height: ${({ theme }) => theme.itemCard.lineHeight};

  ${lessThan(480)} {
    font-size: inherit;
  }
`;

export const Title = styled.p`
  margin: 0;

  ${itemCardTypo}

  text-transform: uppercase;
`;

export const Complement = styled.p`
  margin: 0;
  margin-top: ${({ theme }) => theme.spaces.sd4}rem;

  ${itemCardTypo}
`;

export const ItemCardButton = styled(Button)`
  width: 100%;
`;

const StyledItemCard = styled(Link)`
  position: relative;

  display: flex;
  flex-flow: column;

  height: 100%;
  overflow: hidden;

  text-decoration: none;

  cursor: pointer;

  :hover,
  :focus {
    ${ItemCardButton} {
      ${outlineFocusHoverStyle}
    }
  }
`;

export default StyledItemCard;
