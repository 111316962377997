import styled from 'styled-components';

import { lessThan } from '@utils/mediaQuery';

export const Container = styled.section`
  margin: ${96 / 22}rem 0 ${128 / 22}rem;
  padding: 0 24px;

  ${lessThan(480)} {
    margin: ${60 / 16}rem 0;
  }
`;

export const Wrapper = styled.div`
  max-width: 1408px;
  margin: 0 auto;
`;
