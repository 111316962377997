import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import IconPlus from '../../../images/IconPlus';

import StyledItemCard, {
  Picture,
  ItemCardContent,
  ItemCardText,
  ItemCardButton,
  Title,
  Complement,
} from './ItemCard.styles';

const ItemCard = ({
  title,
  titleAs,
  complement,
  complementAs,
  buttonText,
  picture,
  to,
}) => {
  const sources = {
    ...picture,
  };

  return (
    <StyledItemCard to={to}>
      <Picture fluid={sources} role='presentation' alt='' />

      <ItemCardContent>
        <ItemCardText>
          <Title as={titleAs}>{title}</Title>

          {complement && (
            <Complement as={complementAs}>{complement}</Complement>
          )}
        </ItemCardText>

        <ItemCardButton tag='div' outlined renderIcon={<IconPlus />}>
          {buttonText}
        </ItemCardButton>
      </ItemCardContent>
    </StyledItemCard>
  );
};

ItemCard.propTypes = {
  title: PropTypes.string.isRequired,
  titleAs: PropTypes.string,
  complement: PropTypes.string,
  complementAs: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  picture: PropTypes.shape({}).isRequired,
  to: PropTypes.string.isRequired,
};

ItemCard.defaultProps = {
  titleAs: null,
  complement: null,
  complementAs: null,
};

export default ItemCard;

export const query = graphql`
  fragment ItemCardPicture on File {
    childImageSharp {
      fluid(maxWidth: 416, maxHeight: 318, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
