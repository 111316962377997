// vendors
import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

// utils
import { lessThan, greaterThan } from '@utils/mediaQuery';

export const breakpoint = 1024;

export const Container = styled.div`
  position: relative;

  overflow: hidden;

  ${greaterThan(breakpoint)} {
    display: flex;

    ${({ $bgColorBefore }) =>
      $bgColorBefore &&
      css`
        ::before {
          position: absolute;

          height: ${120 / 22}rem;

          top: 0;
          right: 0;
          left: 0;

          display: block;

          background-color: ${$bgColorBefore};

          content: '';
        }
      `}
    ${({ $bgColorAfter }) =>
      $bgColorAfter &&
      css`
        ::after {
          z-index: -1;
          position: absolute;

          height: ${120 / 22}rem;

          right: 0;
          bottom: 0;
          left: 0;

          display: block;

          background-color: ${$bgColorAfter};

          content: '';
        }
      `}
  }
`;

export const TextContainer = styled.div`
  position: relative;
  z-index: 1;

  display: inline-block;

  padding-left: max(calc(50% - 1408px / 2), 24px);

  color: ${({ theme }) => theme.colors.white};

  background-color: ${({ theme }) => theme.colors.charcoal};

  ${lessThan(breakpoint)} {
    padding-right: max(calc(50% - 1408px / 2), 24px);
  }

  ${greaterThan(breakpoint)} {
    margin-bottom: ${120 / 22}rem;

    ::before {
      position: absolute;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: -1;

      display: block;

      background-color: ${({ theme }) => theme.colors.charcoal};

      transform: skewX(-27deg);
      transform-origin: bottom right;

      content: '';
    }
  }
`;

export const TextWrapper = styled.div`
  margin: ${56 / 16}rem 0;

  ${greaterThan(breakpoint)} {
    width: ${664 / 22}rem;
    max-width: 664px;
    margin: ${120 / 22}rem;
    margin-left: 0;
  }
`;

export const PictureContainer = styled.div`
  position: relative;

  z-index: 0;

  flex-grow: 9999;

  margin-top: ${120 / 22}rem;
  margin-left: ${-120 / 22}rem;
  overflow: hidden;

  ::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;

    width: calc(100% - 482px);

    background-color: ${({ theme }) => theme.colors.secondary};

    content: '';
  }

  ${lessThan(breakpoint)} {
    display: none;
  }
`;

export const PictureWrapper = styled.div`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  box-sizing: content-box;
  max-width: 1008px;

  overflow: hidden;

  transform: skew(-27deg);
  transform-origin: bottom left;
`;

export const Picture = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  right: -50%;
  bottom: 0;
  left: 0;

  max-width: initial !important;
  max-height: initial !important;

  transform: skew(27deg);
  transform-origin: bottom left;
`;
