// vendors
import styled from 'styled-components';
import { em, rem } from 'polished';

// utils
import { lessThan } from '@utils/mediaQuery';
import breakpointsRange from '@utils/breakpointsRange';

// styles
import { titleStyle } from '@styles/global';

export const Container = styled.section`
  padding: ${rem(128, 22)} 24px;

  ${lessThan(480)} {
    padding-top: ${rem(74, 16)};
    padding-bottom: ${rem(74, 16)};
  }

  background-color: ${({ theme }) => theme.colors.veryLightPink};
`;

export const Wrapper = styled.div`
  max-width: 1408px;
  margin: 0 auto;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.layouts.stack(`${theme.spaces.sm4}rem`)}
`;

export const Title = styled.h2`
  ${titleStyle}

  margin: 0;
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme.layouts.switcher(3, rem(80, 22), em(theme.breakpoints[3]))}
`;

export const List = styled.ul`
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;

  ${({ theme }) => theme.layouts.box(theme.colors.text, 'inherit', 0, 0)}

  ${({ theme }) =>
    theme.layouts.stack(`${theme.spaces.sm1}rem`)}

  text-align: center;
`;

export const ImageWrapper = styled.div`
  flex-grow: 1;
`;

export const Image = styled.img`
  max-height: 200px;

  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [138, 234],
        bases: [16, 22],
        unit: 'em',
      },
    ],
    [320, 1024]
  )}
`;

export const Description = styled.p`
  max-width: 408px;
`;
