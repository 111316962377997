// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// components
import HeroHome from '@components/HeroHome';

const query = graphql`
  query {
    heroHomePictureDesktop: file(name: { eq: "img-hero-desktop-home-page" }) {
      childImageSharp {
        ...HeroHomePictureDesktop
      }
    }
    heroHomePictureMobile: file(name: { eq: "img-hero-mobile-home-page" }) {
      childImageSharp {
        ...HeroHomePictureMobile
      }
    }
  }
`;

const HeroSection = ({ children, renderStamp }) => {
  const data = useStaticQuery(query);

  const {
    heroHomePictureDesktop: {
      childImageSharp: { desktop },
    },
    heroHomePictureMobile: {
      childImageSharp: { mobile },
    },
  } = data;

  const pictures = {
    childImageSharp: {
      desktop,
      mobile,
    },
  };

  return (
    <HeroHome pictures={pictures} renderStamp={renderStamp}>
      {children}
    </HeroHome>
  );
};

HeroSection.propTypes = {
  children: PropTypes.node.isRequired,
  renderStamp: PropTypes.node.isRequired,
};

export default HeroSection;
