import styled from 'styled-components';
import { lessThan } from '../../utils/mediaQuery';

import spaces from '../../styles/spaces';

const gutter = `${spaces.sm1}rem`;

export const Grid = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;

  ${({ theme }) => theme.layouts.grid('320px', '80px', 3)}

  ${lessThan(768)} {
    && > * {
      flex: 0 0 calc(80% - ${gutter} * 2);
    }
  }

  ${lessThan(1280)} {
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 0;

    margin: 0 -24px calc(-0.25 * ${gutter});
    padding-bottom: calc(0.75 * ${gutter});

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x proximity;

    ::before,
    ::after {
      flex: 0 0 calc(${gutter} + 10px);

      content: '';
    }

    > * {
      flex: 0 0 calc(45% - ${gutter} * 2);

      :not(:last-child) {
        margin-right: ${gutter};
      }
    }
  }
`;

export const GridItem = styled.li`
  scroll-snap-align: center;
`;
