// vendors
// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import {
  Container,
  TextContainer,
  TextWrapper,
  PictureContainer,
  Picture,
  PictureWrapper,
} from './SkewContainer.styles';

const SkewContainer = ({ children, picture, bgColorBefore, bgColorAfter }) => {
  return (
    <Container $bgColorBefore={bgColorBefore} $bgColorAfter={bgColorAfter}>
      <TextContainer>
        <TextWrapper>{children}</TextWrapper>
      </TextContainer>

      <PictureContainer>
        <PictureWrapper>
          <Picture fluid={picture} />
        </PictureWrapper>
      </PictureContainer>
    </Container>
  );
};

SkewContainer.propTypes = {
  children: PropTypes.node.isRequired,
  picture: PropTypes.shape({
    aspectRatio: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
    srcWebp: PropTypes.string.isRequired,
  }).isRequired,
  bgColorBefore: PropTypes.string,
  bgColorAfter: PropTypes.string,
};

SkewContainer.defaultProps = {
  bgColorBefore: null,
  bgColorAfter: null,
};

export default SkewContainer;
