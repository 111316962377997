import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { hideVisually } from 'polished';

import { h1Style } from '../../styles/global';
import { lessThan, greaterThan } from '../../utils/mediaQuery';

export const breakpoints = [480, 768, 1024];

export const Container = styled.header`
  position: relative;

  display: flex;

  overflow: hidden;

  color: ${({ theme }) => theme.heroHome.colors};
`;

export const TextContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  box-sizing: content-box;

  min-width: 324px;
  min-height: 550px;
  margin-right: 24px;
  padding-left: max(calc(50% - 1408px / 2), 24px);

  background-color: ${({ theme }) => theme.heroHome.bg};

  ::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    display: block;

    background-color: ${({ theme }) => theme.heroHome.bg};

    transform: skewX(-27deg);
    transform-origin: bottom right;

    content: '';
  }

  ${lessThan(breakpoints[2])} {
    ${hideVisually}
  }
`;

export const TextWrapper = styled.div`
  > * {    
    ${h1Style}

    color: ${({ theme }) => theme.heroHome.color};
  }

  > * > * {
    :last-child {
      display: block;
  
      font-weight: ${({ theme }) => theme.fontWeights.light};
    }
  }
`;

export const PictureContainer = styled.div`
  position: relative;
  z-index: -2;

  width: 100%;
  max-width: 908px;

  overflow: hidden;

  ${greaterThan(breakpoints[2])} {
    height: 500px;
  }
`;

export const Picture = styled(GatsbyImage)`
  min-height: 221px;

  ${greaterThan(breakpoints[2])} {
    position: absolute !important;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    max-width: 908px;
  }
`;

export const StampContainer = styled.div`
  position: relative;

  display: flex;
  align-items: flex-end;

  min-width: max(calc(92px / 2 + 24px), 24px);
  padding-right: max(calc(50% - 1408px / 2), 24px);

  background-color: ${({ theme }) => theme.heroHome.altBg};

  ::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 600px;
    height: 100%;

    background-color: ${({ theme }) => theme.heroHome.altBg};
    transform: skewX(-27deg);
    transform-origin: top left;

    content: '';
  }

  ${greaterThan(breakpoints[0])} {
    min-width: inherit;
    margin-right: -48px;
  }

  ${greaterThan(breakpoints[1])} {
    margin-right: -96px;
  }

  ${greaterThan(breakpoints[2])} {
    height: 500px;
  }
`;

export const StampWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: ${92 / 16}rem;

  margin-bottom: ${({ theme }) => theme.spaces.s0}rem;

  transform: translateX(-48px);

  ${greaterThan(breakpoints[0])} {
    position: relative;
  }

  ${greaterThan(breakpoints[1])} {
    width: ${157 / 22}rem;

    margin-bottom: ${({ theme }) => theme.spaces.sm2}rem;

    transform: translateX(-96px);
  }

  ${greaterThan(breakpoints[2])} {
    width: ${218 / 22}rem;
  }
`;
