import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  Container,
  TextContainer,
  TextWrapper,
  PictureContainer,
  Picture,
  StampContainer,
  StampWrapper,
  breakpoints,
} from './HeroHome.styles';

const HeroHome = ({ pictures, renderStamp, children }) => {
  const {
    childImageSharp: { desktop, mobile },
  } = pictures;

  const source = [
    {
      ...desktop,
      media: `(min-width: ${breakpoints[2]}px)`,
      sizes: `(max-width: 1854px) ${(908 / 1854) * 100}vw, 908px`,
    },
    { ...mobile },
  ];

  return (
    <Container>
      <TextContainer>
        <TextWrapper>{children}</TextWrapper>
      </TextContainer>

      <PictureContainer>
        <Picture fluid={source} objectFit='cover' loading='eager' />
      </PictureContainer>

      <StampContainer>
        <StampWrapper>{renderStamp}</StampWrapper>
      </StampContainer>
    </Container>
  );
};

HeroHome.propTypes = {
  pictures: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      desktop: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        srcSetWebp: PropTypes.string.isRequired,
        srcWebp: PropTypes.string.isRequired,
      }).isRequired,
      mobile: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        srcSetWebp: PropTypes.string.isRequired,
        srcWebp: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  renderStamp: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default HeroHome;

export const query = graphql`
  fragment HeroHomePictureDesktop on ImageSharp {
    desktop: fluid(maxWidth: 908, maxHeight: 500, quality: 100) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
      ...GatsbyImageSharpFluidLimitPresentationSize
    }
  }
  fragment HeroHomePictureMobile on ImageSharp {
    mobile: fluid(maxWidth: 908, maxHeight: 500, quality: 100) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
      ...GatsbyImageSharpFluidLimitPresentationSize
    }
  }
`;
